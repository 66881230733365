import * as React from "react"
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import AboutNav from '../components/nav/About';
import SideBar from '../components/sidebar';
import UpNextCardNonStrapi from '../components/ui/UpNextCardNonStrapi';
import "../assets/css/screen.css"

const AboutPage = ({ location }) => (
	<Layout location={location} heading1={['About', <span>UVM Health Advantage</span>]} heading2={['Created with You. ', <span>Guided by Doctors.</span>]}>

		<Seo title="Medicare Plans for NY &amp; VT" meta={[{description: 'Meta'}]} bodyclass="about subpage" />
		<AboutNav />
			
		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">
					<main>
						<h3>UVM Health Advantage is the <span className="heading-highlight">first and only</span> Medicare Advantage plan of its kind in this region.</h3>
						<h4>You inspired us to create UVM Health Advantage.</h4>
						<p>We asked people across Vermont and Northern New York what you want in a Medicare plan. You told us you want a plan that&rsquo;s simpler, easier to use and designed with you. That&rsquo;s why we created UVM Health Advantage &mdash; a whole new Medicare experience. One that&rsquo;s been guided by doctors from the UVM Health Network and Medicare experts at MVP Health Care<sup>&reg;</sup>. With UVM Health Advantage, your providers and your health plan will work together to support you on your personal health journey.</p>

						<h4>UVM Health Advantage is here for you every step of the way.</h4>
						<ul>
							<li key={1}>Our UVM Health Advantage Plan Guides help you understand your options, choose the right plan and make sure your transition to UVM Health Advantage goes smoothly with no disruption to your care.</li>
							<li key={2}>Plus, our UVM Health Advantage Care Guides will help you understand and take full advantage of your beneﬁts, and get the right care and support to stay well or manage ongoing conditions &mdash; giving you more value (because we value you!).</li>
						</ul>

						<h4>Get great coverage and beneﬁts.</h4>
						<p>In addition to personalized guidance, you get valuable beneﬁts and services with UVM Health Advantage, along with access to an extensive regional network of providers and hospitals, including the UVM Health Network. So you can feel conﬁdent about your care and your coverage.</p>

						<UpNextCardNonStrapi to="/about/faq/">FAQs</UpNextCardNonStrapi>

					</main>
					<SideBar location={location} />
				</div>
			</div>
		</div>
	</Layout>
)

export default AboutPage
