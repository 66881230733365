import * as React from "react"
import { Link } from "gatsby"

const About = () => {
	return (
		<div className="about-subnav">
			<div className="constrained">
				<nav aria-label="About Menu">
					<ul>
						<li><Link to="/about/faq/" activeClassName="current">FAQs</Link></li>
						{/* <li><NavLink to="/about/current-mvp-member-info/" activeClassName="current">Current MVP Member Info</NavLink></li> */}
						{/* <li><Link to="/about/star-rating/" activeClassName="current">Star Rating</Link></li> */}
					</ul>
				</nav>
			</div>
		</div>
	)
}

export default About;
